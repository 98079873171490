import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomeView from "../presentation/views/HomeView";
import { NotFoundView } from "../presentation/views/NotFoundView";
import PrivacyPolicy from "../presentation/views/PrivacyPolicy";
import TermsConditions from "../presentation/views/TermsConditions";
import ScrollToTop from "../presentation/components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<NotFoundView />} />
        <Route path="/" element={<HomeView />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
