export function NotFoundView() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 xl:px-8">
      <div className="text-center">
        <p className="text-palette-primary text-base font-semibold">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Pagină negăsită
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Ups, nu am reușit să găsim pagina pe care o căutai.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="hover:bg-palette-dark focus-visible:bg-palette-dark rounded-md bg-blue-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Înapoi la pagina principală
          </a>
          <a
            href="mailto:taxibreazaraluca@gmail.com"
            className="text-sm font-semibold text-gray-900"
          >
            Contactează suportul <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
