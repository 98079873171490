import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import myLogo from "../../assets/logo.svg";

export default function MyHeader({ headerRef }: { headerRef: any }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header ref={headerRef} className="sticky top-0 z-50 bg-white xl:relative">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 font-medium text-black xl:px-8"
      >
        <div className="flex xl:flex-1">
          <a href="#home" className="-m-1.5 p-1.5">
            <span className="sr-only">Bumbleebee Taxi Breaza</span>
            <div className="flex items-center justify-center space-x-4">
              <img alt="Logo" src={myLogo} className="h-16" />
              <div className="flex flex-col pt-3 text-center text-3xl">
                <p className="logo-font leading-4">BUMBLEEBEE</p>
                <p className="logo-font">TAXI BREAZA</p>
              </div>
            </div>
          </a>
        </div>
        <div className="flex xl:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Apri il menu principale</span>
            {mobileMenuOpen ? (
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            ) : (
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            )}
          </button>
        </div>
        <div className="hidden text-lg xl:flex xl:gap-x-12">
          <a href="#home" className="leading-6">
            Home
          </a>
          <a href="#galerie" className="leading-6">
            Galerie
          </a>
          <a href="#testimoniale" className="leading-6">
            Testimoniale
          </a>
        </div>
      </nav>

      {/* Overlay per il menu mobile */}
      <div
        className={`${
          mobileMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        } overflow-hidden bg-white shadow-md transition-all duration-300 ease-in-out xl:hidden`}
      >
        <div className="p-6 text-center">
          <a
            href="#home"
            className="block py-2 text-lg font-medium text-gray-900 hover:bg-gray-50"
            onClick={() => setMobileMenuOpen(false)}
          >
            Home
          </a>
          <a
            href="#galerie"
            className="block py-2 text-lg font-medium text-gray-900 hover:bg-gray-50"
            onClick={() => setMobileMenuOpen(false)}
          >
            Galerie
          </a>
          <a
            href="#testimoniale"
            className="block py-2 text-lg font-medium text-gray-900 hover:bg-gray-50"
            onClick={() => setMobileMenuOpen(false)}
          >
            Testimoniale
          </a>
        </div>
      </div>
    </header>
  );
}
