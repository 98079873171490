import { useRef, useEffect, useState } from "react";

import desktopHeader from "../../assets/cars/cover.jpeg";
import mobileHeader from "../../assets/cars/cover_mobile.jpeg";
import starShape from "../../assets/shapes/star_group.svg";
import arrowShape from "../../assets/shapes/arrow.svg";
import avatar1 from "../../assets/avatar1.jpg";
import avatar2 from "../../assets/avatar2.jpg";
import star from "../../assets/shapes/star_filled.svg";
import gallery1 from "../../assets/cars/gallery1.jpeg";
import gallery2 from "../../assets/cars/gallery2.jpeg";
import gallery3 from "../../assets/cars/gallery3.jpeg";
import gallery4 from "../../assets/cars/gallery4.jpeg";
import gallery5 from "../../assets/cars/gallery5.jpeg";
import MyFooter from "../components/MyFooter";
import MyHeader from "../components/MyHeader";

function HomeView() {
  const headerRef = useRef<HTMLHeadElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState<boolean>(
    window.innerWidth < 1024,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth < 1024);
    };

    if (headerRef.current) {
      const rect = headerRef.current.getBoundingClientRect();
      setHeaderHeight(rect.height);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [headerHeight]);

  return (
    <div className="relative">
      {isMobileOrTablet && (
        <div className="fixed left-0 top-0 z-50 w-full xl:hidden">
          <MyHeader headerRef={headerRef} />
        </div>
      )}

      {/* PAGE 1 */}
      <div
        className="pt-16 xl:pt-0"
        style={{ paddingTop: isMobileOrTablet ? `${headerHeight}px` : "0" }}
      >
        <div className="flex xl:h-screen" id="home">
          <img src={desktopHeader} alt="Cover" className="hidden xl:block" />
          <div className="relative h-full w-full bg-white">
            {isMobileOrTablet ? null : <MyHeader headerRef={headerRef} />}
            <div
              className="flex h-full xl:h-auto xl:items-center xl:justify-center"
              style={{
                height:
                  window.innerWidth >= 1280
                    ? `calc(100vh - ${headerHeight}px)`
                    : "100%",
              }}
            >
              <div className="flex flex-col justify-start space-y-6 px-6 xl:px-16">
                <img src={mobileHeader} alt="Cover" className="xl:hidden" />
                <p className="text-center font-extrabold text-blue-800 xl:text-left">
                  BREAZA, JUD PRAHOVA
                </p>
                <div className="flex flex-col items-start space-x-0 space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
                  <h1 className="text-center text-4xl font-bold xl:text-left xl:text-6xl xl:2xl:text-7xl">
                    Fără griji, <br className="hidden xl:block" />
                    mereu în siguranță <br className="hidden xl:block" />
                    oriunde
                  </h1>
                  <img
                    src={starShape}
                    className="hidden w-16 self-center xl:block xl:w-20 xl:self-start"
                    alt="shape"
                  />
                </div>
                <p className="w-full text-center text-base text-gray-600 xl:w-4/5 xl:text-left xl:text-lg 2xl:text-xl">
                  Transport sigur, confortabil și la timp pentru fiecare
                  călătorie în Breaza. Indiferent de destinație, suntem mereu la
                  un apel distanță, pregătiți să îți oferim cel mai bun serviciu
                  de taxi din zonă.
                </p>
                <div className="flex flex-col items-center xl:items-start">
                  <button
                    type="submit"
                    onClick={() => (window.location.href = "tel:0040793103399")}
                    className="flex w-full justify-center rounded-full bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 xl:w-fit xl:px-6 xl:text-lg 2xl:text-xl"
                  >
                    Suna acum
                  </button>
                  <img
                    src={arrowShape}
                    className="ml-0 mt-4 hidden w-16 xl:ml-20 xl:block xl:w-20"
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* GALLERY */}
        <div
          className="relative flex flex-col items-center justify-center pt-16 text-black xl:mt-12"
          id="galerie"
        >
          <div className="mb-10 flex flex-col items-center justify-center px-4 text-center">
            <h1 className="text-3xl font-bold xl:text-5xl">
              Galerie Foto Taxiuri
            </h1>
            <p className="mt-4 text-base text-gray-700 xl:text-lg">
              Vezi taxiurile noastre în acțiune! Explorează galeria și descoperă
              cum arată mașinile noastre.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 px-4 xl:grid-cols-4 xl:gap-4 xl:px-72">
            <div className="flex">
              <img
                src={gallery1}
                alt="Car"
                className="h-full w-full rounded-xl object-cover"
              />
            </div>

            <div className="grid grid-cols-1 gap-y-4 xl:col-span-2 xl:grid-cols-2 xl:gap-4">
              <img
                src={gallery2}
                alt="Car"
                className="h-full w-full rounded-xl object-cover"
              />
              <img
                src={gallery3}
                alt="Car"
                className="h-full w-full rounded-xl object-cover"
              />
              <div className="col-span-2 flex">
                <img
                  src={gallery4}
                  alt="Car"
                  className="h-full w-full rounded-xl object-cover"
                />
              </div>
            </div>

            <div className="flex">
              <img
                src={gallery5}
                alt=""
                className="h-full w-full rounded-xl object-cover"
              />
            </div>
          </div>
        </div>

        {/* TESTIMONIALS */}
        <div
          className="mt-12 flex flex-col items-center justify-center space-y-16 bg-white p-8 xl:p-16 xl:px-48"
          id="testimoniale"
        >
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-center text-3xl font-bold xl:text-5xl">
              Testimoniale ale clienților
            </h1>
            <p className="mt-4 text-center text-base text-gray-700 xl:text-lg">
              Află ce spun clienții noștri! Citește experiențele și
              feedback-urile lor despre serviciul nostru de transport local și
              descoperă de ce aleg să călătorească cu noi în fiecare zi.
              Satisfacția clienților noștri este prioritatea noastră!
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
            <div className="flex items-center rounded-xl bg-blue-50 p-6 xl:p-10">
              <div className="flex flex-col items-center justify-center space-y-6">
                <p className="text-center text-lg font-bold xl:text-base">
                  "Un serviciu excelent! Șoferul este întotdeauna punctual și
                  amabil. Mă simt în siguranță și confortabil în fiecare
                  călătorie. Recomand cu încredere!"
                </p>
                <div className="flex flex-col items-center space-y-2">
                  <img
                    src={avatar1}
                    className="h-12 w-12 rounded-full object-cover xl:h-16 xl:w-16"
                    alt="Avatar"
                  />
                  <h2 className="text-center text-lg font-semibold xl:text-base">
                    Raul Palade
                  </h2>
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <img
                        key={i}
                        src={star}
                        className="h-5 w-5 xl:h-6 xl:w-6"
                        alt="Star"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center rounded-xl bg-blue-50 p-6 xl:p-10">
              <div className="flex flex-col items-center justify-center space-y-6">
                <p className="text-center text-lg font-bold xl:text-base">
                  "Călătoria cu acest taxi este întotdeauna o plăcere. Prețuri
                  corecte, mașina curată și șofer profesionist. Este soluția mea
                  preferată pentru transportul local."
                </p>
                <div className="flex flex-col items-center space-y-2">
                  <img
                    src={avatar2}
                    className="h-12 w-12 rounded-full object-cover xl:h-16 xl:w-16"
                    alt="Avatar"
                  />
                  <h2 className="text-center text-lg font-semibold xl:text-base">
                    Ionut Bunescu
                  </h2>
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <img
                        key={i}
                        src={star}
                        className="h-5 w-5 xl:h-6 xl:w-6"
                        alt="Star"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <MyFooter />
      </div>
    </div>
  );
}

export default HomeView;
