import facebook from "../../assets/social/facebook.svg";
import instagram from "../../assets/social/instagram.svg";
import whatsapp from "../../assets/social/whatsapp.svg";

function MyFooter() {
  return (
    <div className="flex flex-col items-center justify-center space-y-4 bg-palette-darkGray p-8 text-lg text-white xl:p-16">
      <div className="flex flex-col items-center space-y-4 xl:flex-row xl:space-x-10 xl:space-y-0">
        <a href="/#home">Home</a>
        <a href="/#galerie">Galerie Foto</a>
        <a href="/#testimoniale">Testimoniale</a>
        <a href="/privacy-policy">Privacy & Policy</a>
        <a href="/terms-conditions">Terms & Conditions</a>
      </div>

      <div className="flex space-x-6 xl:space-x-10">
        <a
          href="https://www.facebook.com/profile.php?id=61562266198017"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} className="h-6 xl:h-8" alt="Facebook" />
        </a>
        <a
          href="https://www.instagram.com/taxibreaza_bumblebee/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} className="h-6 xl:h-8" alt="Instagram" />
        </a>
        <a
          href="http://wa.link/hrzlgh"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsapp} className="h-6 xl:h-8" alt="WhatsApp" />
        </a>
      </div>

      <div className="text-center text-lg xl:text-base">
        <p>&copy; Tudose Raluca Georgiana PFA. Toate drepturile rezervate.</p>
      </div>
    </div>
  );
}

export default MyFooter;
